@import 'styles/sizes.scss';

section {
  .content {
    width: 55%;

    h2 {
      font-size: $font-size-ex-large;
      margin: 0;
      margin-bottom: 40px;
    }

    h3 {
      margin: 10px 0;
    }
  }

  &:global(.right) .content {
    margin-left: auto;
    margin-right: 10%;
  }
}

.headingNudge {
  bottom: 30px;
}

.stepsNudge {
  bottom: -10%;
}

.imgLarge {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 35%;
  width: 60%;
  max-width: 1024px;

  :global(.right) & {
    left: unset;
    right: 35%;
  }
}

.stepRow {
  .gettingStartedBackground {
    width: 100%;
    left: -50;
    border-radius: 0;
    border-top-right-radius: 600px;
    border-bottom-right-radius: 600px;
  }

  img {
    width: 100%;
    max-width: 1100px;
  }
}

.row {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  padding: 200px 20px;
  box-sizing: border-box;

  .column70 {
    width: 70%;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .column30 {
    width: 30%;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .content {
    max-width: 500px;
  }
}

@media only screen and (max-width: 1350px) {
  .gettingStartedBackground {
    display: none;
  }

  .row {
    max-width: 1100px;
    padding: 100px 20px;
    flex-direction: column;
    margin: 0 auto;

    .column70,
    .column30 {
      width: 100%;
      margin-bottom: 50px;
      padding: 0;

      &.image {
        order: 1;
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  section {
    padding-bottom: 0;

    &:last-of-type {
      padding-bottom: 80px;
    }

    &:global(.right) {
      .content {
        width: 100%;
        max-width: 800px;
        margin: 0;
      }
    }

    .content {
      width: 100%;
      max-width: 800px;
      margin: 0;
    }
  }

  &:global(.right) {
    .stepsNudge {
      align-self: baseline;
    }
  }

  .stepsNudge {
    position: static;
    align-self: flex-end;
    margin-top: 70px;
  }

  .imgLarge {
    width: 100%;
    max-width: 700px;
    position: static;
    transform: none;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 768px) {


  .row {
    padding: 50px 20px;

    .column50 {
      padding: 0;
    }
  }
}