@import 'styles/colours.scss';
@import 'styles/sizes.scss';
@import 'styles/transitions.scss';
@import 'styles/fonts.scss';

footer {
  padding-top: 50px;
  background-color: $color-background;

  h2:global(.primary) {
    max-width: 65%;
    margin: 0 auto;
    padding-bottom: $space-large;
    padding-top: $space-large;
  }

  h3 {
    font-size: $font-size-medium;
    margin: 0;
    margin-bottom: 20px;
  }

  a.signup {
    padding: 11px 45px 14px 45px;
    border-radius: 50px;
    border: 4px solid;
    box-sizing: border-box;

    font-size: 16px;
    font-family: $font-alt;
    text-decoration: none;
    background-color: $color-primary;
    color: $color-text-light;
    border-color: $color-primary;

    &:hover {
      background-color: $color-primary-highlight;
      border-color: $color-primary-highlight;
      color: #FFF;
    }
  }

  .buttonGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;
  
    button, a {
      margin: 0px 20px;
    }
  
    button.disabledFooterBtn {
      background-color: #AECDD5;
      border-color: #AECDD5;
      color: $color-background;

      &:hover {
        background-color: #AECDD5;
        border-color: #AECDD5;
      }
    }
  }

  a {
    &:hover {
      color: inherit;
    }
  }
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

.footnote {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  box-sizing: border-box;
  padding: $space-large 30px;

  .logo {
    width: 100px;
  }

  .copy {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 5px;

    span {
      opacity: 0.4;
    }
    
    img {
      height: 14px;
      vertical-align: middle;
      opacity: 0.4;
      transition: 0.3s;

      &:hover {
        opacity: 1;
      }
    }
  }

  .socialList {
    display: flex;

    li {
      padding: 0 15px;
    }

    svg {
      font-size: 22px;
      opacity: 0.4;
      transition: $transition-fast;
    }

    a:hover svg {
      opacity: 1;
    }
  }
}

.footerNavigation {
  display: flex;
  justify-content: space-between;
  width: 60%;

  .column {
    text-align: left;
    position: relative;
    padding: 0 30px;
    width: 25%;
    box-sizing: border-box;

    &:not(:last-of-type)::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 70px;
      width: 2px;
      background-image: linear-gradient(#3A3A3A 60%, rgba(255,255,255, 0) 0%);
      background-position: right;
      background-size: 1px 10px;
      background-repeat: repeat-y;
    }

    ul {
      li {
        margin: 10px 0;
      }
    }

    a {
      font-size: $font-size-medium;
    }
  }
}

.subscribeForm {
  max-width: 400px;
  flex-grow: 1;
  text-align: left;
  margin-left: 50px;
  margin-right: 40px;

  .formContainer {
    display: flex;
  }

  input {
    padding: 12px 18px;
    border-radius: 10px 0px 0px 10px;
    outline: none;
    flex-grow: 1;
    border: 2px solid transparent;
    transition: $transition-fast;

    &:active,
    &:focus,
    &:hover {
      border: 2px solid $color-primary-dark;
    }

    &.error {
      border: 2px solid $color-error;
    }

    &:read-only {
      background-color: $color-background;
      color: $color-disabled;
      border: 2px solid $color-disabled;
    }
  }

  button {
    background-color: $color-primary-dark;
    color: $color-text-light;
    font-size: $font-size-medium;
    padding: 12px 18px;
    border-radius: 0px 10px 10px 0px;
    border: none;

    &:disabled,
    &[disabled] {
      background-color: $color-disabled;
    }
  }
}

@media screen and (max-width: 1300px) {
  .subscribeForm {
    margin: 0;
  }

  .footerContainer {
    .footerNavigation {
      .column {
        padding: 0 10px;
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  footer {
    padding: 0 20px;
    box-sizing: border-box;

    h2:global(.primary) {
      width: 100%;
      max-width: 600px;
      padding: 80px 0 40px 0;

      br {
        display: none;
      }
    }

    .buttonGroup {
      flex-direction: column;
      margin-bottom: 30px;

      button {
        margin-bottom: 15px;
      }
    }
  }

  .footerContainer {
    flex-direction: column;
    width: 100%;

    .footerNavigation {
      width: 100%;
      margin: 40px 0;

      .column {
        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }
    }
  }

  .footnote {
    width: 100%;
    padding: $space-large 0;
  }
}

@media only screen and (max-width: 768px) {
  .footerContainer {
    .footerNavigation {
      flex-wrap: wrap;

      .column {
        flex: 50%;
        padding: 0;
        margin-bottom: 20px;
  
        &:not(:last-of-type)::after {
          display: none;
        }

        &:nth-of-type(2n) {
          clear: both;
        }
      }
    }
  }

  .footnote {
    flex-direction: column;
    padding-bottom: 20px;

    a, span {
      margin-bottom: 40px;
    }
    
    ul {
      margin: 0 auto;
    }
  }
}