header .pricingBlurb {
  margin-bottom: 0;
}

.pricingPlanNudge {
  bottom: 30px;
}

.pricingBackground:global(.sectionBackground) {
  height: 90vh;
  width: 1700px;
  left: -760px;
}

.inlineIconBlock {
  display: flex;
  align-items: flex-start;

  img {
    width: 40px;
    margin-right: 50px;
  }

  h3 {
    margin: 0;
  }
}

div.controlNudge {
  bottom: -10%;
}

@media only screen and (max-width: 2000px) {
  .pricingBackground:global(.sectionBackground) {
    height: 90vh;
    width: 1500px;
    left: -760px;
  }
}

@media only screen and (max-width: 1080px) {
  .pricingBackground:global(.sectionBackground) {
    display: none;
  }

  .inlineIconBlock {
    max-width: 700px;
  }
}

@media only screen and (max-width: 768px) {
  .pricingBackground:global(.sectionBackground) {
    display: none;
  }

  div.controlNudge {
    align-self: flex-end;
  }

  .inlineIconBlock {
    flex-direction: column;

    img {
      margin-bottom: 30px;
    }
  }
}