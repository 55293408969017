@import '../../styles/sizes.scss';
@import '../../styles/fonts.scss';
@import '../../styles/colours.scss';
@import '../../styles/transitions.scss';

input,
textarea {
  &.formInput {
    width: 100%;
    padding: 10px 15px;
    font-size: $font-size-medium;
    font-family: $font-default;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 7px;
    outline: none;
    transition: $transition-fast;
    resize: vertical;
  
    &:focus,
    &:hover,
    &:active {
      border: 1px solid $color-primary;
    }
  
    &:focus,
    &:active {
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    }
  }
}