@import 'styles/sizes.scss';

section {
  .content {
    width: 45%;

    h2 {
      margin: 0;
      margin-bottom: 40px;
    }

    h3 {
      margin: 10px 0;
    }

    &:global(.center) {

      width: 45%;
    }

    img {
      max-width: 512px;
      margin-top: $space-large;
    }
  }

  &:global(.right) .content {
    margin-left: auto;
    margin-right: 10%;
  }
}

.imgLarge {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10%;
  width: 35%;
  max-width: 1024px;

  :global(.right) & {
    left: unset;
    right: 35%;
  }
}

.row {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  padding: 200px 20px;
  box-sizing: border-box;

  .column50 {
    width: 50%;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .content {
    max-width: 500px;
  }

  .stepImage {
    width: 100%;
  }

  .apiDetails {
    max-width: 600px;
  }
}

@media only screen and (max-width: 1080px) {
  .row {
    max-width: none;
    padding: 100px 20px;
    flex-direction: column;
    margin: 0;

    .column50 {
      width: 100%;
      margin-bottom: 50px;
      padding: 0;

      &.image {
        order: 1;
      }
    }
  }


  section {
    .content {
      width: 100%;
    }
  }
  
  .imgLarge {
    width: 100%;
    max-width: 700px;
    position: static;
    transform: none;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .row {
    padding: 50px 20px;

    .column50 {
      padding: 0;
    }
  }

  section {
    .content.lastStep {
      width: 250px;

      img {
        max-width: 100%;
        margin-top: 30px;
      }
    }
  }
}