@import 'styles/colours.scss';

.headerTextBlurb {
  width: 50%;
  max-width: 650px;
  font-size: 28px;
  margin-right: auto;

  a {
    color: $color-primary;
    font-size: 28px;
  }
}

@media only screen and (max-width: 1080px) {
  .headerTextBlurb {
    width: auto;
  }
}

@media only screen and (max-width: 768px) {
  .headerTextBlurb {
    font-size: 22px;

    a {
      font-size: 22px;
    }
  }
}