/* Font Sizes */
$font-size-ex-large: 45px;
$font-size-large: 35px;
$font-size-medium: 14px;

/* Font Weights */
$font-weight-bold: 700;
$font-weight-light: 200;

/* Layout Spacing */
$space-large: 100px;

/* Mobile font sizes */
$mobile-font-size-ex-large: 28px;
$font-size-large: 28px;
$font-size-medium: 14px;