.formGroup {
  text-align: left;
  margin-bottom: 50px;

  h3 {
    margin: 0 0 10px 0;
  }

  p {
    margin: 0 0 15px 0;
  }
}