@import 'styles/colours.scss';

form.contact {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;

  button {
    align-self: flex-end;
  }

  .error {
    color: $color-error;
    margin-top: 0;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 1080px) {
  form.contact {
    width: 100%;
  }
}