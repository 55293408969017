@import 'styles/colours.scss';

header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 70px 10%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  
  .logo {
    width: 120px;
  }
}

@media only screen and (max-width: 1300px) {
  .topBar {
    padding: 45px 30px;
  }
}

@media only screen and (max-width: 1080px) {
  .content {
    margin: 0 auto;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .content {
    padding-top: 150px;
  }
}
