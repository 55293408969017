@import 'styles/colours.scss';
@import 'styles/sizes.scss';
@import 'styles/fonts.scss';

.headerBackground {
  height: 100vh;
}

.imgLarge {
  width: 55%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);

  :global(.right) & {
    right: 47.2%;
    left: unset;
  }
}

.buttonGroup {
  display: flex;
  align-items: center;
  margin-right: 40px;

  h3 {
    font-size: $font-size-medium;
    margin: 0;
  }

  a.signUp {
    padding: 11px 45px 14px 45px;
    border-radius: 50px;
    border: 4px solid;
    box-sizing: border-box;

    font-size: 16px;
    font-family: $font-alt;
    text-decoration: none;
    background-color: $color-primary;
    color: $color-text-light;
    border-color: $color-primary;

    &:hover {
      background-color: $color-primary-highlight;
      border-color: $color-primary-highlight;
    }
  }

  a {
    margin-right: 30px;
    color: $color-primary;

    &:hover {
      &::before {
        border-color: $color-primary-highlight;
      }
    }

    &:global(.alt)::before {
      content: '';
      position: absolute;
      bottom: -5px;
      width: 100%;
      height: 10px;
      border-bottom: 2px solid $color-primary;
      // display: none;
    }

    &::after {
      display: none;
    }
  }
}

.largeButtonGroup {
  button {
    margin: 0 50px;
  }
}

.productBenefits {
  margin: 150px 0;
  min-height: auto;

  :global(.fullSection) {
    max-width: 1600px;
    margin: 0 auto;
  }

  h2 {
    max-width: 55%;
    margin: 0;
    text-align: left;
  }
}

.integrationBackground:global(.sectionBackground) {
  left: -55%;
}

.flow {
  display: flex;
  justify-content: center;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 80px;

  img {
    width: 100%;
    max-width: 180px;
    display: block;
    margin: auto;

    &.small {
      width: 30px;
      align-self: center;
      margin: 0 30px;
    }
  }

  .flowBlock {
    display: flex;
    flex-direction: column;

    img {
      margin-bottom: 20px;
    }
    
    h3 {
      max-width: 500px;
      margin: 0 auto;
      font-size: 18px;
    }
  }
}

section:global(.right).integrationSection :global(.splitSection) {
  margin-right: 0;
}

@media only screen and (max-width: 1600px) {
  section:global(.right).integrationSection :global(.splitSection) {
    max-width: 500px;
    margin-right: 5%;
  }
}

@media only screen and (max-width: 1300px) {
  .imgLarge {
    width: 80%;
  }

  section:global(.right).integrationSection :global(.splitSection) {
    max-width: 500px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 1080px) {
  section:global(.right).integrationSection :global(.splitSection) {
    width: 100%;
    max-width: none;
    box-sizing: border-box;
  }

  .imgLarge {
    max-width: 750px;
    width: 100%;
    position: static;
    transform: none;
    order: -1;
    margin: 0 auto;
    margin-bottom: 50px;
  }

  .headerBackground {
    width: calc(100% - 40px);
    height: 480px;
    top: 120px;
    left: 20px;
    right: auto;
    border-top-right-radius: 500px;
  }
  

  .integrationBackground:global(.sectionBackground) {
    left: 20px;
    border-top-right-radius: 0;
    border-top-left-radius: 500px;
  }

  .productBenefits {
    min-height: auto;
    margin: 30px 0;

    h2 {
      max-width: 90%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .headerBackground {
    height: 300px;
  }

  .buttonGroup {
    align-items: baseline;
    margin: 0;
    flex-direction: column;

    a {
      margin: 0;
      margin-bottom: 25px;
    }
  }

  .flow {
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    img {
      max-width: 150px;
    }

    .flowBlock {
      margin-bottom: 80px;
    }

    h3 {
      max-width: 100px;
    }

    .small {
      display: none;
    }
  }
}

@media only screen and (max-width: 500px) {
  .integrationSection {
    padding-top: 120px;
  }
}