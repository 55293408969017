@import 'styles/sizes.scss';

.sectionNudge {
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 10%;
  max-width: 200px;
  order: 10;

  &:global(.right) {
    text-align: right;
    left: auto;
    right: 10%;
  }

  .iconDown {
    width: 20px;
  }

  h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .sectionNudge {
    position: static;
    margin-top: 30px;
    align-self: baseline;

    &:global(.right) {
      align-self: flex-end;
    }
  }
}