@import 'styles/sizes.scss';

section.pricingSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  width: 100%;
  min-height: 90vh;
  max-width: 1600px;
  margin: 0 auto;
  padding: 30px;
  box-sizing: border-box;

  h2 {
    width: 100%;
    max-width: 600px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  section.pricingSection {
    padding: 100px 20px 0 20px;
  }
}