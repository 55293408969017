@import 'styles/colours.scss';
@import 'styles/sizes.scss';
@import 'styles/fonts.scss';

button.genericButton {
  padding: 11px 45px 14px 45px;
  border-radius: 50px;
  background: none;
  border: 4px solid;

  font-size: 16px;
  font-family: $font-alt;
  transition: 0.3s;

  &.primary {
    background-color: $color-primary;
    color: $color-text-light;
    border-color: $color-primary;

    &:hover {
      background-color: $color-primary-highlight;
      border-color: $color-primary-highlight;
    }
  }

  &.secondary {
    color: $color-primary;
    background: none;
    border-color: $color-primary;
  }

  &.disabled {
    background-color: $color-background;
    border-color: $color-background;
    color: $color-font-disabled;
    cursor: not-allowed;

    &:hover {
      background-color: $color-background;
      border-color: $color-background;
    }
  }
}