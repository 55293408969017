.parallaxContainer {
  position: absolute;
  width: 50%;
  left: 55%;
  display: flex;
  align-items: center;

  .innerContainer {
    position: relative;
    width: 100%;
  }
}

.graphicScene {
  position: relative;
  width: 100%;
}

.graphicText {
  width: 22%;
  top: 9%;
  left: 2%;
  position: absolute;
}

.graphicPerson01 {
  width: 10%;
  top: 61%;
  left: 47%;
  position: absolute;
}

.graphicPerson02 {
  width: 13%;
  top: 48%;
  left: -11%;
  position: absolute;
}

@media only screen and (max-width: 1300px) {
  .parallaxContainer {
    width: 80%;
  }
}

@media only screen and (max-width: 1080px) {
  .parallaxContainer {
    max-width: 750px;
    width: 100%;
    position: static;
    transform: none;
    order: -1;
    margin: 0 auto;
    margin-bottom: 50px;
  }
}