@import 'styles/colours.scss';
@import 'styles/sizes.scss';
@import 'styles/fonts.scss';

// TODO: Fix height difference with border for button
a.customLinkButton {
  padding: 11px 45px 14px 45px;
  border-radius: 50px;
  border: 4px solid;
  box-sizing: border-box;

  font-size: 16px;
  font-family: $font-alt;
  text-decoration: none;

  &.primary {
    background-color: $color-primary;
    color: $color-text-light;
    border-color: $color-primary;

    &:hover {
      background-color: $color-primary-highlight;
      border-color: $color-primary-highlight;
    }
  }

  &.secondary {
    color: $color-primary;
    background: none;
    border-color: $color-primary;
  }

  &.disabled {
    background-color: $color-background;
    color: $color-font-disabled;
    cursor: not-allowed;
  }

  svg {
    position: relative;
    top: 4px;
    margin-left: 25px;
    font-size: 20px;
  }
}