@import './styles/colours.scss';
@import './styles/sizes.scss';
@import './styles/transitions.scss';
@import './styles/fonts.scss';

@font-face {
  font-family: 'IsidoraSans-Bold';
  font-weight: normal;
  src: url('assets/fonts/webfonts/3AFA13_0_unhinted_0.woff2') format('woff2'),
  url('assets/fonts/webfonts/3AFA13_0_unhinted_0.woff') format('woff'),
  url('assets/fonts/webfonts/3AFA13_0_unhinted_0.ttf') format('truetype');
}

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: $font-default;
  font-size: $font-size-medium;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-weight: normal;
}

strong {
  font-weight: inherit !important;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button,
p,
svg,
img,
span,
input,
br {
  letter-spacing: 1;

  &::selection {
    background-color: $color-sundance-yellow; //aecdd5
  }
}

.hidden-mobile {
  display: none !important;
}

a {
  &:hover {
    color: $color-primary;
  }
}

a,
button {
  cursor: pointer;
  transition: $transition-basic;
  color: $color-text;
  position: relative;
  font-size: 16px;
  transition: 0.3s;

  &.link {
    text-decoration: none;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 0;
      height: 1px;
      background-color: $color-text;
      transition: width .2s ease;
    }
  
    &:hover::after {
      width: 100%;
    }
  }

  &.primary,
  &.secondary,
  &.alt {
    font-family: $font-alt;
    font-weight: normal;
    text-decoration: none;
  }

  &.primary,
  &.secondary {
    padding: 14px 35px;
    border-radius: 30px;
    border: 2px solid $color-primary;
  }

  &.primary {
    background-color: $color-primary;
    color: $color-text-light;

    &:hover {
      background-color: $color-primary-highlight;
      border-color: $color-primary-highlight;
    }
  }

  &.secondary {
    color: $color-primary;
    background: white;

    &:hover {
      background-color: $color-primary-highlight;
      border-color: $color-primary-highlight;
      color: white;
    }
  }

  &.alt {
    color: $color-primary;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      top: 100%;
      background-color: $color-primary;
      transition: $transition-fast;
    }

    &:hover {
      color: $color-primary-highlight;
      
      &::after {
        top: calc(100% + 3px);
        width: calc(100% + 4px);
        left: -2px;
        background-color: $color-primary-highlight;
      }
    }
  }

  &.large {
    font-family: $font-alt;
    font-weight: normal;
    font-size: 28px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: 0.3s;

    &.active {
      color: $color-primary;
    }

    &.blurred {
      opacity: 0.15;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

h1,
h2,
h3 {
  font-family: $font-alt;
  font-weight: normal;
}

h2 {
  font-size: $font-size-ex-large;
  margin: 0;
  margin-bottom: 40px;
}

h1,
h2.primary {
  font-size: $font-size-ex-large;
  margin: 0;
  margin-bottom: 15px;

  span, strong {
    color: $color-primary;
    white-space: nowrap;
  }

  & ~ p {
    margin-bottom: 90px;
    margin-right: 25%;
  }
}

p {
  font-weight: $font-weight-light;
  font-size: 16px;
  line-height: 1.6;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.splitSection {
  max-width: 700px;
  margin-left: 10%;

  .right & {
    margin-right: 10%;
    margin-left: auto;
  }
}

.fullSection {
  margin: 0 10%;
  padding: 0 20px;
  align-items: center;

  h2.primary {
    margin-bottom: 200px;
  }

  .center & h1,
  .center & h2.primary {
    width: 50%;
    max-width: 600px;
    margin: $space-large auto;
  }

  header & {
    justify-content: space-evenly;
    margin: 100px 10%;
  
    // TODO: Remove replace with component
    .headText {
      width: 50%;
      max-width: 650px;
      font-size: 28px;
      margin-bottom: 0;
      margin-right: auto;

      a {
        color: $color-primary;
        font-size: 28px;
      }
    }
  }
}

.sectionBackground {
  content: '';
  position: absolute;
  width: 100vw;
  height: 90vh;
  background-color: $color-background;
  border-radius: 600px;
  right: -50%;
  top: 0;
  z-index: -1;

  .right &,
  &.left {
    right: unset;
    left: -50%;
  }

  &.right {
    right: -50%;
    left: unset;
  }
}

.center {
  text-align: center;
  margin: 0 auto;
}

.left {
  text-align: left;
}

.pushRight {
  display: flex;
  justify-content: flex-end;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.half {
  width: 50%;
}

.iconsList {
  display: flex;
  text-align: left;

  li {
    flex: 1;
    padding: 0 40px;
    position: relative;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    &:not(:last-of-type)::after {
      content: '';
      position: absolute;
      right: 0;
      top: calc(50% - 70px / 2);
      height: 70px;
      width: 2px;
      background-image: linear-gradient(#3A3A3A 60%, rgba(255,255,255, 0) 0%);
      background-position: right;
      background-size: 1px 10px;
      background-repeat: repeat-y;
    }
  }
}

@font-face {
  font-family: 'OpenSans';
  font-weight: $font-weight-light;
  src: url('assets/fonts/OpenSans-Light.ttf') format('truetype');
}

@media only screen and (max-width: 1300px) {
  .splitSection {
    margin-left: 0;
    padding: 0 20px;
  
    .right & {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .sectionBackground {
    width: calc(100% - 40px);
    height: 480px;
    top: 120px;
    right: 0;
    left: 20;
    border-radius: 0;
    border-top-right-radius: 500px;

    .right &,
    &.left {
      right: unset;
      left: 20px;
    }
  }

  .fullSection {
    header & {
      .headText {
        width: 80%;
      }
    }
  }

  .splitSection {
    width: 100%;
    max-width: none;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .iconsList {
    display: block;
  
    li {
      width: 50%;
      display: inline-block;
      padding: 10px 0;
      box-sizing: border-box;

      &:first-of-type {
        padding-right: 0;
      }

      &:nth-child(2n - 1) {
        padding-right: 20px;
      }

      &:nth-child(2n) {
        padding-left: 20px;
      }

      &:not(:last-of-type)::after {
        display: none;
      }
    }
  }

  .fullSection {
    h2.primary {
      width: 100%;
      max-width: none;
      margin: 50px 0;
    }
  }

  .center {
    .fullSection {
      h2.primary {
        width: 100%;
        max-width: none;
        margin: 50px 0;
      }
    }
  }

  .fullSection {
    margin: 0;
  
    .center & h1,
    .center & h2.primary {
      width: 100%;
      max-width: 600px;
      margin: 60px auto;
    }
  }
}

@media only screen and (max-width: 768px) {
  h2 {
    font-size: $mobile-font-size-ex-large;

    br {
      display: none;
    }
  }
  
  h1,
  h2.primary {
    font-size: $mobile-font-size-ex-large;

    & ~ p {
      margin-bottom: 50px;
      margin-right: 0;
    }
  }

  .half {
    width: 100%;
  }

  .fullSection {
    header & {
      margin: 0;
    }
  }

  .sectionBackground {
    height: 300px;
    top: 30px;
  }

  .iconsList {
    display: block;
  
    li {
      width: 100%;
      display: block;
      padding: 10 0;

      &:first-of-type {
        padding-right: 0;
      }

      &:nth-child(2n - 1) {
        padding-right: 0;
      }

      &:nth-child(2n) {
        padding-left: 0;
      }
    }
  }
}